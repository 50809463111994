import { Injectable, OnDestroy } from '@angular/core';
import {
  HandlerType,
  datadogLogs as datadog,
  datadogLogs,
} from '@datadog/browser-logs';
import { environment } from '../../../environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from '../ngrx';
import { Store, createSelector, select } from '@ngrx/store';
import {
  getContractId,
  getCurrentExerciseId,
  getCurrentLang,
  getCurrentSessionId,
  getPartnerId,
  getPartnerName,
} from '../ngrx/global/global.selectors';

type HandlersArguments = Parameters<typeof datadogLogs.logger.error>;

const contextSelector = createSelector(
  getCurrentExerciseId,
  getCurrentLang,
  getCurrentSessionId,
  getContractId,
  getPartnerId,
  getPartnerName,
  (
    exercise_id,
    language,
    session_id,
    contract_id,
    partner_id,
    partner_name,
  ) => ({
    exercise_id,
    language,
    session_id,
    contract_id,
    partner_id,
    partner_name,
  }),
);

const { version: npmVersion, staging, development, production } = environment;

// Add tag prefix in production - https://hopper-jira.atlassian.net/browse/HTSFA-1051
const version = environment.production ? `v${npmVersion}` : npmVersion;

@Injectable()
export class LoggerService implements OnDestroy {
  private _globalContext: any = {};
  private _curEnv = production
    ? 'production'
    : staging
      ? 'staging'
      : development
        ? 'development'
        : 'local';
  private _service = 'hts-airlines-cfar-claims-app';
  private _site = 'us5.datadoghq.com';
  private _unsubscriber = new Subject();

  private _initConfig = {
    clientToken: 'pubc1227a869b47ba8b221db32fc8caacfc',
    site: this._site,
    service: this._service,
    env: this._curEnv,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    silentMultipleInit: true,
    version,
  };
  private _rumInitConfig = {
    applicationId: '1f974540-31a7-47db-9726-dee5a9b42bb6',
    clientToken: 'pub0646846a67042fe697b2276e99169ebe',
    site: this._site,
    service: this._service,
    env: this._curEnv,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    allowedTracingUrls: [],
    version,
  };

  constructor(private _store: Store<AppState>) {
    datadog.init(this._initConfig);
    datadog.logger.setHandler(this._getHandlerType(this._curEnv));

    this._globalContext = {
      data: {
        jsonPayload: {
          env: this._curEnv,
        },
      },
    };

    datadog.setGlobalContext(this._globalContext);

    this._startRumSession(this._globalContext);

    this._store
      .pipe(select(contextSelector), takeUntil(this._unsubscriber))
      .subscribe((context) => this._addContextInJsonPayload(context));
  }

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // ----------------------------------------------------------
  // PUBLICS METHODS
  // ----------------------------------------------------------

  public info(...args: HandlersArguments) {
    datadog.logger.info.apply(datadog.logger, args);
  }

  public debug(...args: HandlersArguments) {
    datadog.logger.debug.apply(datadog.logger, args);
  }

  public warn(...args: HandlersArguments) {
    datadog.logger.warn.apply(datadog.logger, args);
  }

  public error(...args: HandlersArguments) {
    datadog.logger.error.apply(datadog.logger, args);
  }

  // ----------------------------------------------------------
  // PRIVATES METHODS
  // ----------------------------------------------------------
  private _addContextInJsonPayload(payload: Record<string, any>): void {
    Object.entries(payload).forEach(([key, value]) => {
      this._globalContext.data.jsonPayload[key] = value;
    });

    datadog.setGlobalContext(this._globalContext);
    this._datadogRUM().then((rum) => rum.setGlobalContext(this._globalContext));
  }

  private _getHandlerType(env: string): HandlerType | HandlerType[] {
    switch (env) {
      case 'production':
        return HandlerType.http;
      case 'staging':
        return [HandlerType.http, HandlerType.console];
      default:
        return HandlerType.console;
    }
  }

  private async _startRumSession(context: Record<string, any>): Promise<void> {
    const datadogRum = await this._datadogRUM();
    datadogRum.setGlobalContext(context);
    datadogRum.init(this._rumInitConfig);
    datadogRum.startSessionReplayRecording();
  }

  private async _datadogRUM() {
    const { datadogRum } = await import('@datadog/browser-rum');
    return datadogRum;
  }
}
