import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../ngrx';
import { Store } from '@ngrx/store';
import {
  getCurrentLang,
  getExercise,
  getPartnerId,
} from '../ngrx/global/global.selectors';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  mergeMap,
  Subject,
  takeUntil,
} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Partner } from '../enums/partner.enum';

@Injectable()
export class PartnerI18nService implements OnDestroy {
  private _unsubscriber = new Subject();
  private partnerId$ = this._store.select(getPartnerId);
  private locale$ = this._store.select(getCurrentLang);

  public isVirginAustralia$ = new BehaviorSubject(false);

  constructor(
    private _store: Store<AppState>,
    private _translateService: TranslateService,
  ) {
    this.partnerId$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((partnerId) => {
        if (partnerId === Partner.VIRGIN_AUSTRALIA) {
          this.isVirginAustralia$.next(true);
        }
      });
  }

  // -----------------------------------------------
  // Life Cycle Hooks
  // -----------------------------------------------

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  // -----------------------------------------------
  // Methods
  // -----------------------------------------------

  public footerLogo$ = combineLatest(
    [this.locale$, this.partnerId$],
    (locale, partnerId) => {
      const logoName =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'powered-by-hts-logo'
          : 'provided-by-hts-logo';
      return `assets/${logoName}/${locale}.svg`;
    },
  );

  public pageTitle$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.VIRGIN_AUSTRALIA
          ? 'CFAR_EXERCISE_FLOW.TITLE_WITHOUT_HTS'
          : 'CFAR_EXERCISE_FLOW.TITLE';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );

  public productName$ = this.partnerId$.pipe(
    mergeMap((partnerId) => {
      const translationKey =
        partnerId === Partner.AIR_ASIA
          ? 'COMMON.PRODUCT_NAME_AIR_ASIA'
          : 'COMMON.PRODUCT_NAME';
      return this._translateService.stream(translationKey);
    }),
    takeUntil(this._unsubscriber),
  );
}
