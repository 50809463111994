<ng-container *ngIf="isLoadingConfiguration$ | async">
  <div
    class="loader-container p-16"
    fxFlex="100"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="p-16" ngClass.lt-md="px-16" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
    <div class="p-8" ngClass.lt-md="px-8" fxLayoutAlign="center center">
      <p>{{ 'COMMON.LOADING' | translate }}</p>
    </div>
  </div>
</ng-container>

<app-navbar class="mat-elevation-z6"></app-navbar>

<div class="content" #scrollAnchor>
  <router-outlet (activate)="layoutEventService.scrollToTop()"></router-outlet>
</div>

<footer
  class="mat-elevation-z6 p-16"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <a href="https://hts.hopper.com/" target="_blank">
    <img class="hts-logo" src="{{ partnerI18n.footerLogo$ | async }}" />
  </a>
</footer>
