// http://www.loc.gov/standards/iso639-2/php/code_list.php

import { CustomLocale } from '../enums/language-code.enum';

import * as English from './locales/en/common.json';
import * as Spanish from './locales/es/common.json';
import * as French from './locales/fr/common.json';
import * as Indonesian from './locales/id/common.json';
import * as Japanese from './locales/ja/common.json';
import * as Khmer from './locales/km-kh/common.json';
import * as Korean from './locales/ko/common.json';
import * as Malay from './locales/ms/common.json';
import * as Russian from './locales/ru/common.json';
import * as Thai from './locales/th/common.json';
import * as Turkish from './locales/tr/common.json';
import * as Vietnamese from './locales/vi/common.json';
import * as SimplifiedChinese from './locales/zh-hans/common.json';
import * as TraditionalChinese from './locales/zh-hant/common.json';

// Availables Languages
export const Locales = new Map<CustomLocale, any>([
  [CustomLocale.EN, English],
  [CustomLocale.EN_AU, English],
  [CustomLocale.EN_US, English],
  [CustomLocale.ES, Spanish],
  [CustomLocale.FR, French],
  [CustomLocale.FR_CA, French],
  [CustomLocale.ID, Indonesian],
  [CustomLocale.JA, Japanese],
  [CustomLocale.KM_KH, Khmer],
  [CustomLocale.KO, Korean],
  [CustomLocale.MS, Malay],
  [CustomLocale.RU, Russian],
  [CustomLocale.TH, Thai],
  [CustomLocale.TR, Turkish],
  [CustomLocale.VI, Vietnamese],
  [CustomLocale.ZH_CN, SimplifiedChinese],
  [CustomLocale.ZH_HANS, SimplifiedChinese],
  [CustomLocale.ZH_HANT, TraditionalChinese],
  [CustomLocale.ZH_HK, TraditionalChinese],
]);
