import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../ngrx';
import { Store, select } from '@ngrx/store';
import { getCurrentLang } from '../ngrx/global/global.selectors';
import { Subject, take, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { CountryCode } from '../enums/country-code.enum';
import { StateCode } from '../enums/state-code.enum';
import { CustomLocale } from '../enums/language-code.enum';

@Injectable()
export class LocationI18nService implements OnDestroy {
  public mapCountries = new Map<string, string>();
  public mapStates = new Map<string, string>();
  private _unsubscriber = new Subject();

  constructor(
    private _store: Store<AppState>,
    private _translateService: TranslateService,
  ) {
    this._store
      .pipe(select(getCurrentLang), takeUntil(this._unsubscriber))
      .subscribe((currentLang: CustomLocale) =>
        this._updateTranslations(currentLang),
      );
  }

  ngOnDestroy(): void {
    this._unsubscriber.next(null);
    this._unsubscriber.complete();
  }

  private _updateTranslations(currentLang: CustomLocale): void {
    const countries = Object.values(CountryCode);
    const states = Object.keys(StateCode);
    const label = new Intl.DisplayNames([currentLang as string], {
      type: 'region',
    });

    countries.forEach((countryCode) =>
      this.mapCountries.set(countryCode, label.of(countryCode) || ''),
    );

    states.forEach((stateCode) => {
      // Get Label and fill the map
      this._translateService
        .get('COMMON.AMERICAN_STATE.' + stateCode)
        .pipe(take(1))
        .subscribe((label) => {
          this.mapStates.set(stateCode, label);
        });
    });
  }
}
