import { Locale as DateFnsLocale } from 'date-fns';
import { CustomLocale } from '../enums/language-code.enum';

export const dateFnsLocales: Record<
  CustomLocale,
  () => Promise<{ default: DateFnsLocale }>
> = {
  [CustomLocale.EN]: () =>
    import(`@/../node_modules/date-fns/locale/en-US/index.js`),
  [CustomLocale.EN_US]: () =>
    import(`@/../node_modules/date-fns/locale/en-US/index.js`),
  [CustomLocale.EN_AU]: () =>
    import(`@/../node_modules/date-fns/locale/en-AU/index.js`),
  [CustomLocale.ES]: () =>
    import(`@/../node_modules/date-fns/locale/es/index.js`),
  [CustomLocale.FR]: () =>
    import(`@/../node_modules/date-fns/locale/fr/index.js`),
  [CustomLocale.FR_CA]: () =>
    import(`@/../node_modules/date-fns/locale/fr/index.js`),
  [CustomLocale.ID]: () =>
    import(`@/../node_modules/date-fns/locale/id/index.js`),
  [CustomLocale.JA]: () =>
    import(`@/../node_modules/date-fns/locale/ja/index.js`),
  [CustomLocale.KM_KH]: () =>
    import(`@/../node_modules/date-fns/locale/km/index.js`),
  [CustomLocale.KO]: () =>
    import(`@/../node_modules/date-fns/locale/ko/index.js`),
  [CustomLocale.MS]: () =>
    import(`@/../node_modules/date-fns/locale/ms/index.js`),
  [CustomLocale.RU]: () =>
    import(`@/../node_modules/date-fns/locale/ru/index.js`),
  [CustomLocale.TH]: () =>
    import(`@/../node_modules/date-fns/locale/th/index.js`),
  [CustomLocale.TR]: () =>
    import(`@/../node_modules/date-fns/locale/tr/index.js`),
  [CustomLocale.VI]: () =>
    import(`@/../node_modules/date-fns/locale/vi/index.js`),
  [CustomLocale.ZH_CN]: () =>
    import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  [CustomLocale.ZH_HANS]: () =>
    import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  [CustomLocale.ZH_HANT]: () =>
    import(`@/../node_modules/date-fns/locale/zh-CN/index.js`),
  [CustomLocale.ZH_HK]: () =>
    import(`@/../node_modules/date-fns/locale/zh-HK/index.js`),
};

const SUPPORTED_LOCALES = Object.values(CustomLocale);

export const isSupportedLocale = (input: string): input is CustomLocale =>
  SUPPORTED_LOCALES.includes(input as CustomLocale);

export function getLanguageFromSession(
  language: string,
  pointOfSale: string,
): CustomLocale {
  const languageCodeLowerCase = getHWLocaleFromLocale(language);
  const pointOfSaleLowerCase = pointOfSale.toLowerCase();
  const regionalLocale = `${languageCodeLowerCase}-${pointOfSaleLowerCase}`;

  if (isSupportedLocale(regionalLocale)) {
    return regionalLocale;
  }
  if (isSupportedLocale(languageCodeLowerCase)) {
    return languageCodeLowerCase;
  }
  const firstLanguageCodeMatch = SUPPORTED_LOCALES.find((locale) =>
    locale.startsWith(languageCodeLowerCase),
  );
  return firstLanguageCodeMatch || CustomLocale.EN;
}

/**
 * Expected format for Hyperwallet : 2 letters in lowercase.
 * Eg. 'en'
 */
export function getHWLocaleFromLocale(locale: string): string {
  // Eg. 'en-us' > 'en'
  return locale.toString().slice(0, 2).toLowerCase();
}

/**
 * Expected format for Angular
 * > See @/../node_modules/@angular/common/locales folder
 */
export function getAngularLocalefromLocale(locale: CustomLocale): string {
  let angularLocale = locale as string;

  // Special cases
  switch (locale) {
    case CustomLocale.EN:
    case CustomLocale.EN_US:
      angularLocale = 'en';
      break;
    case CustomLocale.EN_AU:
      angularLocale = 'en-AU';
      break;
    case CustomLocale.FR_CA:
      angularLocale = 'fr-CA';
      break;
    case CustomLocale.ZH_CN:
    case CustomLocale.ZH_HK:
    case CustomLocale.ZH_HANT:
      angularLocale = 'zh';
      break;
    case CustomLocale.ZH_HANS:
      angularLocale = 'zh-Hans';
      break;
    case CustomLocale.KM_KH:
      angularLocale = 'km';
      break;
  }

  return angularLocale || 'en';
}

export function getLabelFromLocale(
  locale: CustomLocale,
  language: CustomLocale,
): string {
  return (new Intl.DisplayNames([language], { type: 'language' }).of(locale) ||
    '') as string;
}
