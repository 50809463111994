import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class LayoutEventsService {
  @Output() scrollUp = new EventEmitter();

  scrollToTop() {
    this.scrollUp.emit();
  }
}
